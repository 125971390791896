/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named

import { buildMenu, Layout } from "../../layouts";
import { rtfFromText } from "@bond-london/graphcms-rich-text";
import { Researchblock } from "../../components/DownloadBlock";
import { GA_paragraph } from "../../components/GA_paragrph";
import { GA_CTA } from "../../components/GA_CTA";
import socialHead4 from "../../images/corporate-social.png";
import infogaphicImage1 from "../../images/Socialvalues-infographic.png";
import infogaphicImage2 from "../../images/SocialvaluesVertical.png";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: socialHead4,
      srcSet: socialHead4,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Corporate social responsibility | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Corporate social responsibility"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We are honoured to be a signatory of the United Nations Global Compact, a Corporate Social Responsibility initiative."
        headline=""
        cleanedMessage={rtfFromText(
          "The UN Global Compact is more than a voluntary initiative; it is a call to companies everywhere to align their operations and strategies with universal principles in the areas of human rights, labour, environment and anti-corruption."
        )}
        id={""}
        className=""
      />
      <Researchblock
        quote="The Sustainable Development Goals (SDGs) were adopted by the United Nations (UN) in 2015 as a global call to action to end poverty, protect the planet and ensure that by 2030 all people enjoy peace and prosperity."
        Title="We extend our gratitude to UN Global Compact for its unfailing, incredible work to support and enable businesses to be a 'force for good' and create a better world for all."
        Link=""
        className="ga-text-color ga-line-height-42"
        contentclass="ga-font-style-s24 "
        btn="no"
        infograph={[
          {
            value: `GOAL 1: No Poverty GOAL
            \n
             2: Zero Hunger  GOAL 3: Good Health and Well-being  GOAL 4: Quality Education GOAL 5: Gender Equality GOAL 6: Clean Water and Sanitation`,
          },
          {
            value: `GOAL 7: Affordable and Clean Energy
              GOAL 8: Decent Work and Economic Growth
              GOAL 9: Industry, Innovation and Infrastructure
              GOAL 10: Reduced Inequality
              GOAL 11: Sustainable Cities and Communities
              GOAL 12: Responsible Consumption and Production
              `,
          },
          {
            value: `GOAL 13: Climate Action
            GOAL 14: Life Below Water
            GOAL 15: Life on Land
            GOAL 16: Peace and Justice Strong Institutions
            GOAL 17: Partnerships to achieve the Goal`,
          },
        ]}
        infographtitlclassName=""
        infographTitle="The 17 SDG goals are integrated to the extent that action in one area will affect outcomes in others"
      />
      <GA_paragraph
        id="info1"
        CarbonInfographImage={infogaphicImage1}
        CarbonInfographImage1={infogaphicImage2}
      />
      <GA_paragraph
        id={""}
        title=""
        messagez="In October 2021, Partners in Performance joined the Global Compact Network Australia’s SDG Ambition Programme, a programme focused on fully integrating the SDGs into our core strategic and operational business management, to help build more inclusive and sustainable societies. 
        The six-month accelerator programme will challenge us to set ambitious corporate targets that are critical to spearheading the transformation of our economies and societies. We have pledged to use its core skills of practical problem-solving to design and implement innovative new systems to hard-wire SDG goals across the organisation to deliver lasting impact and materially accelerate progress towards changing our world."
        className="ga-paragraph-title"
        btnName="Read more"
        btnclassName="ga-paragraph-button"
        btnLink="https://www.unglobalcompact.org/participation/report/cop/create-and-submit/active/464885"
      />
      <GA_CTA
        id={""}
        title1="Inclusion & Diversity"
        title2="Climate Action"
        Link1="/en/diversity"
        Link2="/en/climate-action"
      />
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
